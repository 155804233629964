import { AnimatedTextBlock } from "@components/atoms/AnimatedTextBlock";
import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper";
import { Button } from "@components/atoms/Button";
import { Pill } from "@components/atoms/Pill";
import { SiteState } from "@components/site/SiteState";
import { IconArrowTopRight } from "@components/svg/Icons";
import { db } from "@utils/firebaseConfig";
import { defineBlock, EditableText } from "eddev/blocks"
import { useRoute, useRouter } from "eddev/routing";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useSnapshot } from "valtio";

export const meta: BlockMeta = {
  title: "Home Stats",
}

type StatsItemType = {
  label: string;
  value: number;
  description: string;
}

async function writeClipboardText(text: string) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error: any) {
    console.error(error.message)
  }
}

const Toast = () => {
  return (
    <div className="fixed bottom-[calc(var(--spacing-4)+var(--size-toolbarHeight))] left-0 w-full pointer-events-none flex justify-center items-center z-[100]">
      <div className="p-4 bg-black text-white rounded-sm">
        Copied to clipboard
      </div>
    </div>
  )
}

export default defineBlock("home/stats", (props) => {
  const state = useSnapshot(SiteState)
  const countries = state.statsCountries
  const kiwis = state.statsKiwis
  const others = state.statsOthers
  const views = state.statsViews

  const stats: StatsItemType[] = [
    // { label: "Countries", value: countries, description: "Number of countries that have pledged to help NZ become the best place in the world to have herpes" },
    // { label: "Everyone’s doing it", value: kiwis + others, description: "Number of people who’ve helped make New Zealand the best place in the world to have herpes." },
    // { label: "Everyone else", value: others, description: "Number of people who have helped NZ become the best place in the world to have herpes" },
    { label: "Engagement", value: views * 2, description: "See how many minutes people have devoted to watching the Herpes Destigmatisation Course." },
    { label: "Lesson views", value: views, description: "Number of lessons watched to help make New Zealand the best place in the world to have herpes." },
  ]

  const [selected, setSelected] = useState(1)
  const [copied, setCopied] = useState(false)
  const stat = stats[selected]
  const router = useRouter()
  const route = useRoute()

  const completeTheCourse = async () => {
    if (route.pathname !== "/") {
      await router.navigate("/")
    }
    SiteState.scrollToCourse()
  }

  const copyToClipboard = async () => {
    writeClipboardText("https://thebestplaceintheworldtohaveherpes.com")
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const spreadClick = () => {
    SiteState.setUserShared()

    try {
      if (typeof navigator.share !== "undefined") {
        navigator.share?.({
          title: document.title,
          text: "Make New Zealand the best place in the world to have herpes",
          url: "https://thebestplaceintheworldtohaveherpes.com",
        })
      } else {
        copyToClipboard()
      }
    } catch (error: any) {
      copyToClipboard()
    }
  }

  return (
    <>
      <BlockWrapper theme="dark" toolbarState="course-progress">
        <BlockItem className="bg-orange">
          <div className="flex gap-2">
            {stats.map((item, index) => (
              <Pill key={index} className={twMerge("bg-orange-light cursor-pointer transition-colors hover:bg-black hover:text-white", selected === index ? "bg-black text-white" : "")} onClick={() => setSelected(index)}>{item.label}</Pill>
            ))}
          </div>
          <div className="flex flex-col md:flex-row md:justify-between md:items-end">
            <div className="flex flex-col md:flex-col-reverse md:w-1/2">
              <p className="mt-10 type-title-xxl md:mt-[14vw]">
                {`${stat.value.toLocaleString()} `}
              </p>
              {stats.map((item, index) => stat.label === item.label ? (
                <AnimatedTextBlock key={index} textKey={item.label} className={twMerge("mt-7 type-body-l md:mt-5")} triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder={item.description} repeat={true} />
              ) : null)}
            </div>
            <div className="pt-6 mt-6 border-t border-black flex justify-between items-center w-full md:w-auto md:border-none md:flex-row-reverse md:gap-3">
              {/* <Button onClick={() => completeTheCourse()} className="bg-white *:*:px-0" large>
                <EditableText
                  as="span"
                  className=""
                  store="stats-complete"
                  inlineToolbar={false}
                  defaultValue="Complete the course"
                  placeholder="Complete the course"
                />
              </Button> */}
              <Button onClick={() => spreadClick()} icon={<IconArrowTopRight style={{ width: "1.7em", height: "1.7em" }} />} className="*:*:px-0 hover:bg-orange-light" large reverse>
                <EditableText
                  as="span"
                  className=""
                  store="stats-spread"
                  inlineToolbar={false}
                  defaultValue="Spread the word"
                  placeholder="Spread the word"
                />
              </Button>
            </div>
          </div>
        </BlockItem>
      </BlockWrapper>
      {copied && <Toast />}
    </>
  )
})